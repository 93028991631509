import EbookBannerComponent from '@pretto/bricks/website/shared/components/EbookBanner'

import * as S from '../styles'

const EbookBanner = () => (
  <S.BlockLarge>
    <EbookBannerComponent />
  </S.BlockLarge>
)

export default EbookBanner
